import React, { useState, useEffect, Fragment } from "react"
import Select from 'react-select';
import { Form, Dropdown, ButtonGroup } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import $ from "jquery";
import axios from 'axios';
// styles
import "./OffPlanSearchResult.scss"
import Development from "../OffPlanDetails/Development/Development";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import {
  parseSearchUrl,
  propertyH1,
  savedSearchParams
} from "../../components/property-search/utils";
import _ from "lodash"
import SeoResults, { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "../../components/property-search/seoResults"

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""
const styles = {
  control: styles => ({ ...styles, backgroundColor: '#f7fbff', border: 0, outline: 0, boxShadow: 'none', fontSize: '14px', color: '#263140', minHeight: '3rem', paddingLeft: '1rem', marginBottom: '1rem' }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#263140',
    }
  }
}

const filterstyles = {
  control: styles => ({ ...styles, width: '180px', backgroundColor: '#f7fbff', border: 0, outline: 0, boxShadow: 'none', fontSize: '14px', color: '#263140', minHeight: '3rem', paddingLeft: '1rem', marginBottom: '1rem' }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#263140',
    }
  }
}

const filterSortstyles = {
  control: styles => ({ ...styles, width: '180px', backgroundColor: '#f7fbff', border: 0, outline: 0, boxShadow: 'none', fontSize: '14px', color: '#263140', minHeight: '3rem', paddingLeft: '1rem' }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#263140',
    }
  }
}

const clickHandle = (e) => {
  const li = e.target.closest('li');
  li.closest('ul').querySelectorAll('li').forEach(item => {
    if (item !== li) {
      item.classList.remove('active');
    }
  })
  e.target.closest('li').classList.toggle('active');
}






// markup
const OffPlanSearchResult = (props) => {

  const [arrow, setArrow] = useState(false);
  const [filter, setFilter] = useState(false);
  const [stateFilter, setStateFilter] = useState({
    beds: null,
    min: null,
    max: null,
    type: null,
    search: null,
    sort: null

  });
  const hits = props.propertiesToShow
  const offPlanMinPrice = hits.reduce((minPrice, hit) => {
    return hit.Price < minPrice ? hit.Price : minPrice;
  }, hits[0].Price);

  const offPlanMaxPrice = hits.reduce((maxPrice, hit) => {
      return hit.Price > maxPrice ? hit.Price : maxPrice;
  }, hits[0].Price);
  if (typeof window !== 'undefined') {
    localStorage.setItem('offPlanMinPrice', offPlanMinPrice)
    localStorage.setItem('offPlanMaxPrice', offPlanMaxPrice)
  }
  const filterRefine = () => {
    setFilter(!filter)
  }

  const arrowClick = () => {
    setArrow(!arrow)
    props.filter.sort = !arrow;
    props.offPlanFilter(props.filter)
  }

  useEffect(() => {
    $(document).mouseup(function (e) {
      var container = $(".price-filter-dropdown");

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".price-filter-dropdown").removeClass("active");
      }
    });
  })

  const clearFilter = () => {
    props.filter.searchLocation = "";
    props.filter.searchKeyword = "";
    props.filter.propertyType = "";
    props.filter.priceMaxValue = 0;
    props.filter.priceMinValue = 0;
    props.filter.beds = 0;
    props.offPlanFilter(props.filter)
    stateFilter.beds = null;
    stateFilter.min = null;
    stateFilter.max = null;
    stateFilter.type = null;
    stateFilter.search = "";
    setStateFilter(stateFilter)
  }

  const submitSearch = (e) => {
    e.preventDefault();
    props.offPlanFilter(props.filter);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Off-Plan-Search',
      'formType': 'Off-Plan-Search',
      'formId': 'Off Plan Search',
      'formName': 'Off Plan Search',
      'formLabel': 'Off Plan Search',
    });
  }
  const location =useLocation();
  const fullpath = location.pathname
  const searchParams = parseSearchUrl("sales", fullpath);
  //const searchParams = parseSearchUrl(props.ptype, props.fullpath);
     console.log('lllllll', searchParams, "searchParams")
    const saveSearchParams = savedSearchParams(searchParams);

    var H1 = propertyH1(searchParams, false);
    var h1 = propertyH1(searchParams, false);
    var meta_title = h1.includes("Sale") ? ` | Office, Shops & Retail Space` : ` | Shop, Retail, & Office Spaces`;
    h1 += meta_title;
    const introH1 = propertyH1(searchParams, true);

    const areaName = _.capitalize(searchParams.areas); 

    const pType = searchParams.pType;

    let propertyType = "";
    if (searchParams.type == "") {
      propertyType = "properties "
    } else {
      var typeReplace = searchParams.type
      switch (typeReplace) {
        case "office":
          typeReplace = "offices";
          break;
        case "warehouse":
          typeReplace = "warehouses";
          break;
        case "retail":
          typeReplace = "retails";
          break;
        case "shop":
          typeReplace = "shops";
          break;
        case "whole-building":
          typeReplace = "whole-buildings";
          break;
        case "business-center":
          typeReplace = "business-centers";
          break;
        case "labor-camp":
          typeReplace = "labor-camps";
          break;
        case "showroom":
          typeReplace = "showrooms";
          break;
        case "staff-accomodation":
          typeReplace = "staff-accomodations";
          break;
        case "restaurant":
          typeReplace = "restaurants";
          break;
        case "farm":
          typeReplace = "farms";
          break;
      }
      propertyType = typeReplace;
    }

    const actionStr = ACTION_TYPES[pType];
    const actionDescStr = ACTION_DESC_TYPES[pType];
    // const res = introH1
    //     .replaceAll('properties', 'property')
    //     .replaceAll(/\s\s+/g, ' ');
    var meta_description =`Dacha real estate is a leading company that offers the best deals on off-plan properties in Dubai. Visit our website to know more about our off-plan projects in Dubai.`;
              
    var description = meta_description;
    if (searchParams.type) {
      description = `Check out ${introH1} with Dacha Real Estate, expert real estate agents in Dubai.`
    }
    if (searchParams.price.min || searchParams.price.max) {
      description = `Discover the range of ${introH1}. Book an appointment with one of our property experts in ${areaName}.`
    }
    if (searchParams.bedrooms) {
      description = `Browse through the ${introH1}. Check here for the finest collection of ${areaName} properties.`
    }
    if ((searchParams.price.min || searchParams.price.max) && searchParams.type) {
      description = `Find the collection of ${introH1} here. Dacha real estate offers a wide range of ${(propertyType)}${actionStr}${areaName}.`
    }
    if ((searchParams.price.min || searchParams.price.max) && searchParams.bedrooms) {
      description = `Find ${introH1} with Dacha Real Estate. Contact us for more details about properties in ${areaName}.`
    }
    if (searchParams.type && searchParams.bedrooms) {
      description = `Discover the best ${introH1}. Contact Dacha, the leading real estate agents in ${areaName}.`
    }
    if ((searchParams.price.min || searchParams.price.max) && searchParams.type && searchParams.bedrooms) {
      description = `Check out ${introH1} with Dacha Real Estate. Our property experts help you find the perfect ${(propertyType)} in ${areaName}.`
    }
    var intoCopy = `Search for ${introH1} with Dacha Real Estate. Refine your search using the filters above and for more information about ${(propertyType)} in ${areaName}, Please `
    const isPageLoadBg = (typeof fullpath === "undefined");

    // Geo toggle tablet & mobile
    const geoPinClick = (e) => {
      $(".property_map_pin").removeClass("active");
      $(".property_map_list").addClass("active");

      $(".property_list_results").addClass("d-none");
      $(".right-side").removeClass("mobile-none");
    }
    //console.log('searchParams', searchParams)
    const geoToggleClick = (e) => {
      $(".property_map_list").removeClass("active");
      $(".property_map_pin").addClass("active");

      $(".property_list_results").removeClass("d-none");
      $(".right-side").addClass("mobile-none");
    }
    //Geo toggle tablet & mobile

    // const filterRefine = (e) => {
    //   $(".filter-section").removeClass("refine");
    //   $("#filter-one").hide();
    //   $("#filter-two").show();
    // }
    // const filterRefineone = (e) => {
    //   $(".filter-section").addClass("refine");
    //   $("#filter-one").show();
    //   $("#filter-two").hide();
    // }
    var defaultStatus = ["For Sale"];
    if (props.ptype === "lettings") {
      defaultStatus = ["To Let"];
    }
    let mystatus = ''
    if(defaultStatus){
      if(Array.isArray(defaultStatus)){
        mystatus = '(status:"'+(defaultStatus).join('" OR status:"')+'")'
      } else {
        mystatus = '(status:"'+(defaultStatus)+'")'
      }
    }

    let [reviewCount, setReviewCount] = React.useState(0);
    let [averageRating, setAverageRating] = React.useState(0);
    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }
     const urlReviws = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`;
      const getitems = async url => {
        try {
          const { data } = await axios.get(url,strapiconfig)// could be from env files 
          setReviewCount(data.length);
          let sumOfScores = 0;
          data.map((review) => {
            const reviewStringValue = review.starRating;
            sumOfScores += wordToNumber[reviewStringValue];
          })
          setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
        } catch (error) {
          // cache it if fail/error;
    
        }
      }
    useEffect(() => {
        getitems(urlReviws);
      }, []);  
        

  const logoBlue = `${process.env.GATSBY_SITE_URL}/images/logo-blue.svg`;
  const offPlanMinimumPrice = typeof window !== 'undefined' ? localStorage.getItem('offPlanMinPrice') : null
  const offPlanMaximumPrice = typeof window !== 'undefined' ? localStorage.getItem('offPlanMaxPrice') : null
   console.log(searchParams,"searchParams");
   const sParamType= searchParams.type ? searchParams.type.charAt(0).toUpperCase() + searchParams.type.slice(1) + "s" : "Off plan properties";
  return (
    <>
    {offPlanMinimumPrice && offPlanMaximumPrice && (
      <Helmet>
        <script type="application/ld+json">{`{
          "@context": "http://schema.org",
          "@type": "Product",
          "name" : "${sParamType}${actionDescStr}in ${searchParams.areas}",
          "image": "${logoBlue}",
          "description": "${description}",
          "brand": {
            "@type": "Organization",
            "name": "Dacha Real Estate"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${averageRating}",
            "reviewCount": "${reviewCount}"
          },
          "offers": {
            "@type": "AggregateOffer",
            "lowPrice": ${offPlanMinimumPrice},
            "highPrice": ${offPlanMaximumPrice},
            "priceCurrency": "AED"
          }
        }`}</script>
      </Helmet>
    )}
    <section>
      <div className={filter ? "off-plan-filter-section " : "off-plan-filter-section refine"}>
        <div className="filter-section-heading d-xl-none refine-search">
          <div className="refine-filter" onClick={filterRefine}>Refine Your Search <i className="icon-angle-down-big-white"></i></div>
        </div>
        <Form className="container" onSubmit={(e) => { submitSearch(e) }}>
          <div className="row px-xl-4 filter-group justify-content-start">
            <div className="filter-search">
              <div className="filter-search-field filter-search-area">
                <i class="search-icon"></i>
                <Form.Control type="search" placeholder="Location or Developer or Keyword" value={stateFilter.search} onChange={(e) => {
                  props.filter.searchLocation = e.target.value;

                  setStateFilter({ search: e.target.value })
                }} />
              </div>
              {/*<div className="filter-search-field">
                <Form.Control type="search" placeholder="Search by keyword" onChange={(e) => {
                  props.filter.searchKeyword = e.target.value;
                  props.offPlanFilter(props.filter)
                }} />
              </div> */}
            </div>
            <div className="filter-select">
              {/* <div className="filter-select-field">
                <ul className="filter-list">
                  <li className="price-filter-dropdown">
                    <button type="button" onClick={clickHandle}>Price</button>
                    <div className="filter-dropdown">
                      <div className="select">
                        <MinpriceSelectbox {...props} stateFilter={stateFilter} setStateFilter={() => { setStateFilter(filter) }} />
                      </div>
                      <div className="select">
                        <MaxpriceSelectbox  {...props} stateFilter={stateFilter} setStateFilter={() => { setStateFilter(filter) }} />
                      </div>
                    </div>
                  </li>
                </ul>
              </div> */}
              <div className="filter-select-field filter-select-width">
                <MinpriceSelectbox {...props} stateFilter={stateFilter} setStateFilter={() => { setStateFilter(filter) }} />
              </div>
              <div className="filter-select-field filter-select-width">
                <MaxpriceSelectbox  {...props} stateFilter={stateFilter} setStateFilter={() => { setStateFilter(filter) }} />
              </div>
              <div className="filter-select-field filter-select-width">
                <Select
                  name="beds"
                  options={[
                    { value: "", label: "Bedrooms" },
                    { value: '1', label: '1 +' },
                    { value: '2', label: '2 +' },
                    { value: '3', label: '3 +' },
                    { value: '4', label: '4 +' },
                    { value: '5', label: '5 +' },
                    { value: '6', label: '6 +' },
                    { value: '7', label: '7 +' },
                    { value: '8', label: '8 +' },
                    { value: '9', label: '9 +' }
                  ]}
                  value={stateFilter.beds}
                  // menuIsOpen={true}
                  //  defaultValue={currentRefinement.max || ""}
                  placeholder="Beds"
                  classNamePrefix={"select-opt"}
                  onChange={(e) => {
                    props.filter.beds = e.value;
                    setStateFilter({ beds: e })
                  }}
                  //components={{ DropdownIndicator:() => <SelectArrow />, IndicatorSeparator:() => null }}
                  styles={styles}
                />
              </div>
              <div className="filter-select-field filter-select-width">
                <Select
                  name="building"
                  options={[
                    { value: "", label: "All Properties" },
                    { value: "apartments", label: "Apartments" },
                    { value: "villas", label: "Villas" },
                    // { value: "townhouse", label: "Townhouse" },
                    // { value: "penthouse", label: "Penthouse" },
                    // { value: "land", label: "Land" },
                    // { value: "office", label: "Office" },
                    // { value: "warehouse", label: "Warehouse" },
                    // { value: "retail", label: "Retail" },
                    // { value: "plot", label: "Plot" },
                    // { value: "full-floor", label: "Full floor" },
                    // { value: "hotel-apartment", label: "Hotel/Hotel Apartment" },
                    // { value: "shop", label: "Shop" },
                    // { value: "whole-building", label: "Whole Building" },
                    // { value: "property", label: "Others" },
                  ]}
                  value={stateFilter.type}
                  // menuIsOpen={true}
                  placeholder="Property Type"
                  classNamePrefix={"select-opt"}
                  onChange={(e) => {
                    props.filter.propertyType = e.value;
                    setStateFilter({ type: e })
                  }}
                  styles={styles}
                />
              </div>
              <div className="search-filter-button">
                <button type="submit" class="btn btn-primary btn btn-primary search-button">Submit</button>
              </div>
            </div>

          </div>
          <div className="result-clear" onClick={() => { clearFilter() }}>Clear all</div>

          <div className="filter-short">
            {/* <div className="price-short-filter" onClick={arrowClick}>
              <i className={arrow ? "short-icon short-icon-low" : "short-icon"}></i>
              <span className="toggle-text">{arrow ? " Lowest" : " Highest"} Price</span>
            </div> */}
            <div className="price-short-filter">
              <Select
                name="sort"
                options={[
                  { value: "", label: "Filter/Sort By" },
                  { value: 'highest', label: 'Highest Price' },
                  { value: 'lowest', label: 'Lowest Price' }
                ]}
                //value={stateFilter.beds}
                isSearchable={false}
                // menuIsOpen={true}
                placeholder="Filter/Sort By"
                classNamePrefix={"select-opt"}
                onChange={(e) => {
                  props.filter.sort = e.value;
                  props.offPlanFilter(props.filter)
                  setStateFilter({ sort: e })
                }}
                //components={{ DropdownIndicator:() => <SelectArrow />, IndicatorSeparator:() => null }}
                styles={filterSortstyles}
              />
            </div>
          </div>

          <div className="search-result-short">
          <div className="search-result-title">
            {props.count !== 0 ? (
              <>
                <h1><strong>{props.count}</strong> Dubai Off Plan Projects</h1>
                <p class ="desc d-none d-md-block search_text">Explore Dacha Real Estate's portfolio of exciting off-plan projects in Dubai. Use our advanced filtering tools to find the perfect property. <a href="/contact">contact</a> our team of experts for information on Dubai's latest off-plan developments.</p>
              </>
            ) : (
              <>
                <h2>Unfortunately, we do not currently have any properties that match your search criteria.</h2>
                <p>We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the bar above.</p>
              </>
            )}
          </div>

            {
              props.count === 0 &&
              <div className="no_results">
                {props.propertiesToShow && props.propertiesToShow.map((property, i) => {
                  if (i < 5) {
                    return (<Fragment key={i}><Development data={property} type={`list`} /></Fragment>)
                  }
                })}
              </div>
            }


          </div>
        </Form>
      </div>
    </section>
    </>
  )
}


const priceminRange = [
  {
    value: "0",
    label: "No Min"
  },
  {
    value: "50000",
    label: "50,000 AED"
  },
  {
    value: "100000",
    label: "100,000 AED"
  },
  {
    value: "200000",
    label: "200,000 AED"
  },
  {
    value: "300000",
    label: "300,000 AED"
  },
  {
    value: "400000",
    label: "400,000 AED"
  },
  {
    value: "500000",
    label: "500,000 AED"
  },
  {
    value: "600000",
    label: "600,000 AED"
  },
  {
    value: "700000",
    label: "700,000 AED"
  },
  {
    value: "800000",
    label: "800,000 AED"
  },
  {
    value: "900000",
    label: "900,000 AED"
  },
  {
    value: "1000000",
    label: "1,000,000 AED"
  },
  {
    value: "1100000",
    label: "1,100,000 AED"
  },
  {
    value: "1200000",
    label: "1,200,000 AED"
  },
  {
    value: "1300000",
    label: "1,300,000 AED"
  },
  {
    value: "1400000",
    label: "1,400,000 AED"
  },
  {
    value: "1500000",
    label: "1,500,000 AED"
  },
  {
    value: "1700000",
    label: "1,700,000 AED"
  },
  {
    value: "2000000",
    label: "2,000,000 AED"
  },
  {
    value: "2500000",
    label: "2,500,000 AED"
  },
  {
    value: "3000000",
    label: "3,000,000 AED"
  },
  {
    value: "4000000",
    label: "4,000,000 AED"
  },
  {
    value: "5000000",
    label: "5,000,000 AED"
  },
  {
    value: "6000000",
    label: "6,000,000 AED"
  },
  {
    value: "7000000",
    label: "7,000,000 AED"
  },
  {
    value: "8000000",
    label: "8,000,000 AED"
  },
  {
    value: "9000000",
    label: "9,000,000 AED"
  },
  {
    value: "10000000",
    label: "10,000,000 AED"
  },
  {
    value: "20000000",
    label: "20,000,000 AED"
  },
  {
    value: "30000000",
    label: "30,000,000 AED"
  }
]
const MinpriceSelectbox = (props) => {
  let minPriceRange = priceminRange;
  if (maxpriceval > 0) {
    minPriceRange = priceminRange.filter((x) => x.value == "0" || parseInt(x.value) < parseInt(maxpriceval))
  }
  return (
    <Select
      name="minprice"
      options={minPriceRange}
      // menuIsOpen={true}
      value={props.stateFilter.min}
      classNamePrefix={"select-opt"}
      placeholder="Min Price"
      onChange={(e) => {
        minpriceval = e.value
        props.filter.priceMinValue = e.value;
        props.setStateFilter({ min: e })
      }}

      styles={styles}
    />
  )
}


const pricemaxRange = [
  {
    value: "0",
    label: "No Max"
  },
  {
    value: "50000",
    label: "50,000 AED"
  },
  {
    value: "100000",
    label: "100,000 AED"
  },
  {
    value: "200000",
    label: "200,000 AED"
  },
  {
    value: "300000",
    label: "300,000 AED"
  },
  {
    value: "400000",
    label: "400,000 AED"
  },
  {
    value: "500000",
    label: "500,000 AED"
  },
  {
    value: "600000",
    label: "600,000 AED"
  },
  {
    value: "700000",
    label: "700,000 AED"
  },
  {
    value: "800000",
    label: "800,000 AED"
  },
  {
    value: "900000",
    label: "900,000 AED"
  },
  {
    value: "1000000",
    label: "1,000,000 AED"
  },
  {
    value: "1100000",
    label: "1,100,000 AED"
  },
  {
    value: "1200000",
    label: "1,200,000 AED"
  },
  {
    value: "1300000",
    label: "1,300,000 AED"
  },
  {
    value: "1400000",
    label: "1,400,000 AED"
  },
  {
    value: "1500000",
    label: "1,500,000 AED"
  },
  {
    value: "1700000",
    label: "1,700,000 AED"
  },
  {
    value: "2000000",
    label: "2,000,000 AED"
  },
  {
    value: "2500000",
    label: "2,500,000 AED"
  },
  {
    value: "3000000",
    label: "3,000,000 AED"
  },
  {
    value: "4000000",
    label: "4,000,000 AED"
  },
  {
    value: "5000000",
    label: "5,000,000 AED"
  },
  {
    value: "6000000",
    label: "6,000,000 AED"
  },
  {
    value: "7000000",
    label: "7,000,000 AED"
  },
  {
    value: "8000000",
    label: "8,000,000 AED"
  },
  {
    value: "9000000",
    label: "9,000,000 AED"
  },
  {
    value: "10000000",
    label: "10,000,000 AED"
  },
  {
    value: "20000000",
    label: "20,000,000 AED"
  },
  {
    value: "30000000",
    label: "30,000,000 AED"
  }
]


const MaxpriceSelectbox = (props) => {
  let maxPriceRange = pricemaxRange;
  let maxpricedisable = ''
  if (minpriceval > 0) {
    maxPriceRange = pricemaxRange.filter((x) => x.value == "0" || parseInt(x.value) > parseInt(minpriceval))
  }
  if (minpriceval == "30000000") {
    maxpricedisable = 'disabled'
  }
  return (
    <Select
      name="maxprice"
      options={maxPriceRange}
      value={props.stateFilter.min}
      classNamePrefix={"select-opt"}
      placeholder="Max Price"
      onChange={(e) => {
        maxpriceval = e.value;
        props.filter.priceMaxValue = e.value;
        props.setStateFilter({ min: e })
      }}
      //components={{ DropdownIndicator:() => <SelectArrow />, IndicatorSeparator:() => null }}
      styles={styles}
    />
  )
}








export default OffPlanSearchResult